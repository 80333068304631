import ConfiguratorCart from 'views/ConfiguratorCart'
import ConfiguratorLayout from 'components/Configurator/Layout'
import { PageProps } from 'gatsby'
import React, { FC, useEffect } from 'react'
import SEO from 'components/seo'
import { sessionStore } from 'utils/StorageUtils'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { trackStep } from 'utils/GtmTracking'
import { ProductSubLine } from 'types/Common'

type DataProps = {
  productSubline?: { productSubline: ProductSubLine }
  loading: boolean
}

const ConfiguratorCartPage: FC<PageProps<DataProps>> = () => {
  useEffect(() => {
    const partnerId = sessionStore?.getItem('partnerId')

    if (partnerId) {
      trackStep(partnerId, 4)
      setTimeout(() => scrollTo('#gatsby-focus-wrapper'), 0)
    }
  }, [])

  return (
    <ConfiguratorLayout activePageId="cart">
      <SEO title="Konfigurátor košík" />
      <ConfiguratorCart />
    </ConfiguratorLayout>
  )
}

export default ConfiguratorCartPage
